<template>
  <div class="text-center">
    <v-bottom-sheet v-model="show" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon v-text="'fa-plus'" />
        </v-btn>
      </template>
      <v-sheet class="text-center">
        <v-card color="transparent" flat>
          <v-card-title>
            Yeni Hatırlatma
            <v-spacer></v-spacer>
            <v-btn icon @click="show = !show">
              <v-icon v-text="'fa-times'" />
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field placeholder="Başlık" v-model="$v.reminder.title.$model" :error-messages="titleErrors()">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-alert dark border="top" color="red lighten-2" dense v-if="$v.reminder.reminderDate.$anyError">
                  <div v-for="(error, index) in reminderDateErrors()" :key="`err-${index}`">
                    {{ error }}
                  </div>
                </v-alert>
                <v-date-picker
                  no-title
                  :min="$moment().add(1, 'days').format('YYYY-MM-DD')"
                  dense
                  v-model="$v.reminder.reminderDate.$model"
                  full-width
                ></v-date-picker>
              </v-col>
            </v-row>
            <v-btn text block color="primary" @click="addReminder"> Kaydet </v-btn>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import validations from './_validations';

export default {
  name: 'AddReminder',
  mixins: [validationMixin, validations],
  data() {
    return {
      show: false,
      reminder: {
        title: null,
        reminderDate: null,
      },
    };
  },
  methods: {
    addReminder() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit('add-reminder', this.reminder, (response) => {
        if (response) this.show = !this.show;
      });
    },
  },
  watch: {
    show: {
      handler() {
        this.reminder.title = null;
        this.reminder.reminderDate = null;
        this.$v.$reset();
      },
    },
  },
};
</script>

<style></style>
