/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const validations = {
  validations: {
    reminder: {
      title: {
        required,
      },
      reminderDate: {
        required,
      },
    },
  },
  methods: {
    titleErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.reminder.title;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir başlık giriniz.`);
      return errors;
    },
    reminderDateErrors() {
      const errors = [];
      const { $dirty, required } = this.$v.reminder.reminderDate;
      if (!$dirty) return errors;
      !required && errors.push(`Lütfen bir tarih seçiniz.`);
      return errors;
    },
  },
};

export default validations;
