<template>
  <v-card color="transparent" flat>
    <v-card-title>
      Hatırlatmalar
      <v-spacer></v-spacer>
      <add-reminder @add-reminder="add" v-if="hasAddReminderLimit" />
    </v-card-title>
    <v-card-text>
      <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">{{ confirmTitle }}</v-card-title>
          <v-card-text>{{ confirmText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="hideConfirmDialog"> Vazgeç </v-btn>
            <v-btn color="green darken-1" text :loading="isReminderLoading" @click="confirmFunc"> Evet </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="6" v-for="(reminder, index) in reminders" :key="`reminder-${index}`">
          <v-card
            :color="reminder.hasAlert ? 'red darken-2' : 'primary'"
            dark
            class="d-flex flex-column align-center justify-space-around text-center"
            height="180"
          >
            <div class="font-weight-light text-center">
              {{ reminder.title }}
            </div>
            <div>
              <div
                class="font-weight-bold"
                style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, 0%)"
              >
                {{ reminder.restTime }}
              </div>
              <v-icon v-text="'far fa-calendar'" size="100"></v-icon>
            </div>
            <div>
              {{ $moment(reminder.reminderDate).format('YYYY-MM-DD') }}

              <v-btn small icon @click="showConfirmDialog(reminder)" v-if="reminder.id > 0">
                <v-icon size="13" v-text="'fa-trash'"></v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import { ADD_REMINDER, DELETE_REMINDER, FETCH_REMINDERS } from '@/store/modules/reminder.module';
import { mapActions, mapGetters } from 'vuex';
import AddReminder from '../add/Index.vue';
export default {
  name: 'Reminder',
  components: {
    // Carousel,
    // Slide,
    AddReminder,
  },
  data() {
    return {
      confirmTitle: '',
      confirmText: '',
      confirmDialog: false,
      confirmFunc: () => {},
      markedToBeConfirm: null,
    };
  },
  computed: {
    ...mapGetters(['reminders', 'isReminderLoading', 'hasAddReminderLimit']),
  },
  created() {
    this.$nextTick(() => {
      this.fetchReminders();
    });
  },
  methods: {
    ...mapActions({
      fetchReminders: FETCH_REMINDERS,
      addReminder: ADD_REMINDER,
      deleteReminder: DELETE_REMINDER,
    }),
    add(reminder, cb) {
      this.addReminder(reminder)
        .then(() => {
          this.fetchReminders();
          cb(true);
        })
        .catch(() => {
          cb(false);
        });
    },
    delete() {
      this.deleteReminder(this.markedToBeConfirm).then(() => {
        this.hideConfirmDialog();
      });
    },
    showConfirmDialog(reminder) {
      this.confirmTitle = 'Hatirlatmayi sil!';
      this.confirmText = 'Bu hatırlatmayı istediğinizden emin misiniz?';
      this.confirmFunc = this.delete;
      this.markedToBeConfirm = reminder;
      this.confirmDialog = true;
    },
    hideConfirmDialog() {
      this.markedToBeConfirm = null;
      this.confirmDialog = false;
    },
  },
};
</script>

<style></style>
